/* eslint-disable @typescript-eslint/no-explicit-any */

const DictionaryDataReducer = (state: any, action: any) => {
  switch (action.type) {
    case "UPDATE_STATE": {
      const newState = {
        ...state,
        ...action.dictionaryData,
      };

      return newState;
    }

    default: {
      console.log(`Unknown type: ${action.type}`);
      return state;
    }
  }
};

export default DictionaryDataReducer;
