import React from "react";
import { createDropdownList } from "../components/submitElements/steps/createDropdownList";
import getApiData from "../lib/getApiData";

const endpoints = [
  "dd/authors",
  "dd/items/typeOfInstruments",
  "dd/items/researchAreas",
  "dd/items/dataTypes",
  "dd/items/linguisticTargets",
  "dd/languages",
  "dd/items/funders",
  "dd/items/fileTypes",
  "dd/items/participantTypes",
  "dd/items/proficiencies",
  "dd/items/domainOfUse",
  "dd/items/journals",
  "dd/items/publishers",
  "dd/items/rights",
];

const getDictionaryData = async () => {
  try {
    const responses = await Promise.all(
      endpoints.map((endpoint) => getApiData({ endpoint }))
    );

    const dictionaryData = await Promise.all(
      responses.map(async (response, i) => {
        const endpointName = endpoints[i].split("/").pop();
        const dropdownList = await createDropdownList(response.data);
        return { [endpointName]: dropdownList };
      })
    );

    const finalData = Object.assign({}, ...dictionaryData);

    return finalData;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export default getDictionaryData;
