import { isNumber } from "lodash";

const globalContextReducer = (state, action) => {
  switch (action.type) {
    case "mobileSettings": {
      const newState = { ...state };
      newState[action.fieldName] = action.value;
      return newState;
    }
    default:
      console.log("action.type", action.type);
      throw new Error("Bad Action Type");
  }
};

export default globalContextReducer;
