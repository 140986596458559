import { isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import getDictionaryData from "../helpers/getDictionaryData";

import {
  DictionaryDataState,
  DictionaryDataDispatch,
} from "../context/DictionaryDataContext";

function LoadDictionaryData({ children }) {
  const dictionaryDataState = useContext(DictionaryDataState) || "";
  const dictionaryDataDispatch = useContext(DictionaryDataDispatch) || "";

  /**
   * Fetch all Dictionary Data
   */
  useEffect(() => {
    (async () => {
      if (isEmpty(dictionaryDataState)) {
        console.log("🚀 ~ Start loading DictionaryData (HOC)");

        // Wait for all data to be loaded from API
        await getDictionaryData().then((res) => {
          // Store all dictionary data into the DictionaryData Context
          dictionaryDataDispatch({
            type: "UPDATE_STATE",
            dictionaryData: res,
          });
        });
      }
    })();
  }, []);

  return children;
}

export default LoadDictionaryData;
