/* eslint-disable no-shadow */
enum IrisElementTypes {
  DATA_DICTIONARY = "DD",
  NEW = "New",
}

// basic IRIS element type, free text OR linked DD item
type IrisElement = {
  type: IrisElementTypes;
  value: string;
};

enum PUBLICATION_TYPES {
  JOURNAL_ARTICLE = "Journal Article",
  BOOK = "Book",
  CHAPTER = "Chapter",
  PROCEEDINGS = "Proceedings",
  THESIS = "Thesis",
  OASIS = "OASIS Link",
}

enum ACKNOWLEDGEMENT_TYPES {
  JOURNAL_ARTICLE = "Journal Article",
  BOOK = "Book",
  CHAPTER = "Chapter",
  PROCEEDINGS = "Proceedings",
  THESIS = "Thesis",
  ELECTRONIC_RESOURCE = "ElectronicResource",
  OTHER_RESOURCE = "Other",
}

// name
interface IrisName {
  firstName: string;
  lastName: string;
  fullName: string;
}

// define common attributes for journal, book, proceedings, chapter
interface CommonPublicationAttributes {
  publicationType: PUBLICATION_TYPES | ACKNOWLEDGEMENT_TYPES;
  author: IrisName[];
  title: string;
}

// define journal article
interface JournalArticle extends CommonPublicationAttributes {
  journalName: IrisElement[];
  publicationDate: string;
  volume?: string;
  issue?: string;
  pageFrom?: string;
  pageTo?: string;
  identifier?: string[];
}

// define book
interface Book extends CommonPublicationAttributes {
  publicationDate: string;
  placeOfPublication: string;
  publisher: IrisElement[];
  identifier?: string[];
}

// define chapter
interface Chapter extends CommonPublicationAttributes {
  bookTitle: string;
  publicationDate: string;
  placeOfPublication: string;
  publisher: IrisElement[];
  pageFrom?: string;
  pageTo?: string;
  editor: IrisName[];
  identifier?: string[];
}

// define proceedings
interface Proceedings extends CommonPublicationAttributes {
  publicationDate: string;
  conferenceName: string;
  identifier?: string[];
  editor?: IrisName[];
}

// define thesis
interface Thesis extends CommonPublicationAttributes {
  awardingInstitution: string;
  dateOfAward: string;
  identifier?: string[];
}

// define OasisLink
interface OasisLink extends CommonPublicationAttributes {
  OasisLink: string;
}


// define electronic resource
interface ElectronicResource extends CommonPublicationAttributes {
  description: string;
}

// define Other resouce type
interface OtherResource {
  publicationType: PUBLICATION_TYPES | ACKNOWLEDGEMENT_TYPES;
  customPublicationType: string;
  description: string;
}

interface IIrisMaterial {
  objects: [];
  instrument: {
    creator: IrisName[];
    materialType: IrisElement[];
    primaryMaterialType: IrisElement | string;
    researchArea: IrisElement[];
    typeOfFile: string[];
    requiredSoftware?: IrisElement[];
    dataType?: IrisElement[];
    linguisticFeature?: IrisElement[];
    language?: string[];
    sourceOfFunding?: IrisElement[];
    title: string[];
    reliability: string[];
    notes: string;
  };
  participant: {
    type?: IrisElement[];
    firstLanguage?: string[];
    languageBeingLearned?: string[];
    genderOfLearners?: string[];
    proficiencyOfLearners?: IrisElement[];
    typeOfInstruction?: string[];
    amountOfInstruction?: string[];
    lengthOfResidence?: string[];
    ageOfLearner?: string[];
    ageOfArrival?: string[];
    ageOfStudents?: string[];
    amountOfPriorInstruction?: string[];
    timeSpent?: string[];
    yearsOfExperience?: string[];
    domainOfUse?: IrisElement[];
  };
  publication: (JournalArticle | Book | Chapter | Proceedings | Thesis)[];
  acknowledgement?: {
    type: "earlierVersion" | "otherSource";
    publication:
      | JournalArticle
      | Book
      | Chapter
      | Proceedings
      | Thesis
      | ElectronicResource
      | OtherResource;
  }[];
  settings: {
    rights: IrisElement[];
    feedback: "1" | "2" | "3";
    email: string;
    notifyDownloads: "true" | "false";
    comments?: string;
    licenceAgreement: "true" | "false";
  };
}

export {
  IIrisMaterial,
  PUBLICATION_TYPES,
  ACKNOWLEDGEMENT_TYPES,
  IrisElementTypes,
  IrisElement,
  IrisName,
  CommonPublicationAttributes,
  JournalArticle,
  Book,
  Chapter,
  Proceedings,
  Thesis,
  ElectronicResource,
  OtherResource,
};
