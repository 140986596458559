import React, { useReducer, createContext } from "react";
import authenticationReducer from "../reducers/AuthenticationReducer";

export const AuthenticationState = createContext(null);
export const AuthenticationDispatch = createContext(null);

let initialState = {
  isAuthenticated: false,
  username: "public",
  token: undefined,
};

if (typeof window !== 'undefined') {
  initialState = {
    isAuthenticated: localStorage.getItem("isAuthenticated")
      ? localStorage.getItem("isAuthenticated")
      : false,
    username: localStorage.getItem("username")
      ? localStorage.getItem("username")
      : "public",
    token: localStorage.getItem("idToken")
      ? localStorage.getItem("idToken")
      : undefined,
  };
}

const AuthenticationContext = ({ children }) => {
  const [state, dispatch] = useReducer(authenticationReducer, initialState);

  return (
    <AuthenticationState.Provider value={state}>
      <AuthenticationDispatch.Provider value={dispatch}>
        {children}
      </AuthenticationDispatch.Provider>
    </AuthenticationState.Provider>
  );
};

export default AuthenticationContext;
