const searchResultsContextReducer = (state: any, action: any) => {
  switch (action.type) {
    case "saveSearchResultsHandler": {
      let newState = { ...state };
      newState["searchResults"] = action.searchResults;
      return newState;
    }

    default:
      console.log("action.type", action.type);
      throw new Error("Bad Action Type");
  }
};

export default searchResultsContextReducer;
