/* eslint-disable no-console */
import axios, { AxiosRequestConfig } from "axios";

interface getApiDataProps {
  endpoint: string;
  params?: any;
  headers?: any;
  method?: string;
  errorMessage?: string;
  debug?: boolean;
}

const getApiData = async (props: getApiDataProps) => {
  try {
    if (!process.env.GATSBY_IRIS_API) {
      if (process.env.GATSBY_DEBUG_SHOW_CONSOLE) {
        console.log("🚀 ~ API endpoint not specified.");
      }
      return { status: 400, msg: "API endpoint not specified." };
    }

    const apiUrl = process.env.GATSBY_IRIS_API + props.endpoint;

    if (props.debug) {
      console.log("🚀 ~ getApiData ~ apiUrl", apiUrl);
      console.log("🚀 ~ getApiData ~ props", props);
    }

    const requestConfig: AxiosRequestConfig = {
      method: props.method || "get",
      url: apiUrl,
      params: props.method === "get" ? props.params : undefined,
      headers: props.headers,
      data:
        props.method === "post" ||
        props.method === "put" ||
        props.method === "delete"
          ? props.params
          : undefined,
    };

    const response: AxiosResponse = await axios.request(requestConfig);

    const { status, data } = response;

    if (props.debug) {
      console.log("🚀 ~ getApiData ~ requestConfig:", requestConfig);
      console.log("🚀 ~ getApiData ~ response:", response);
      console.log("🚀 ~ getApiData ~ data:", data);
    }

    return { status, data };
  } catch (error) {
    if (process.env.GATSBY_DEBUG_SHOW_CONSOLE) {
      console.log("🚀 ~ API error:", error);
    }

    return {
      status: 400,
      data: "",
      msg:
        props.errorMessage || "Unable to load results. Please try again later.",
    };

    /**
     * At this point we should send a slack message to our channel so we can investigate.
     */
  }
};

export default getApiData;
