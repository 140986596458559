import React, { useReducer, createContext } from "react";
import searchContextReducer from "../reducers/SearchContextReducer";

export const SearchStateContext = createContext(null);
export const SearchDispatchContext = createContext(null);

const initialState: any = {
  searchData: {
    keyword: "",
    from: 0,
    size: 10,
    filters: {},
  },
};

const SearchContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchContextReducer, initialState);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
};

export default SearchContextProvider;
